import { AxiosInstance } from "axios";
import { useCallback } from "react";
import { useAuthToken } from "../passwordless-auth";

export default function useFetcher(
  api: AxiosInstance,
  financialInstitutionId?: string
) {
  const tokenHelper = useAuthToken(financialInstitutionId);

  const fetcher = useCallback(
    (url: string) => {
      return api
        .get(url, {
          headers: {
            Accept: "application/vnd.cotribute.v2+json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data);
    },
    [api]
  );

  const fetchWithToken = useCallback(
    async (params: [string, string]) => {
      const url = params[0];
      const token = params[1];
      return api
        .get(url, {
          headers: {
            Accept: "application/vnd.cotribute.v2+json",
            "Content-Type": "application/json",
            Authorization: tokenHelper.formatAuthHeader(token),
          },
        })
        .then((res) => res.data);
    },
    [api, tokenHelper]
  );

  return {
    fetcher,
    fetchWithToken,
  };
}
