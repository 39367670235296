import { api } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";
import { PlaidAccount } from "@dreambigger/shared/src/types";

// All the paths for this api domain.
const basePath = "/plaid";
const plaidPaths = {
  createIncomeLinkToken: `${basePath}/income-verification/link/token/create`,
  createLinkTokens: `${basePath}/link-tokens`,
  createLinkTokenForIDV: `${basePath}/link-tokens/identity-verification`,
  retrieveLinkTokens: `${basePath}/link-tokens/retrieve`,
  getAccountById: (id: string) => `${basePath}/accounts/${id}`,
  auth: `${basePath}/auth`,
};

type LinkTokenOptions = {
  returnUrl: string;
};

export default function usePlaid(financialInstitutionId: string) {
  const tokenHelper = useAuthToken(financialInstitutionId);

  // create a link token for Plaid's Auth flow
  // POST /plaid/link-tokens
  const createLinkToken = async ({
    institutionName,
    linkCustomizationName,
    redirectUri,
    options,
  }: {
    institutionName?: string;
    linkCustomizationName?: string;
    redirectUri: string;
    options: LinkTokenOptions;
  }): Promise<{
    linkToken: string;
  }> => {
    const token = tokenHelper.getJwtToken();
    const { data } = await api.post<{
      linkToken: string;
    }>(
      plaidPaths.createLinkTokens,
      {
        institutionName,
        linkCustomizationName,
        redirectUri,
        options,
      },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(token),
        },
      }
    );
    return data;
  };

  // retrieve a link token for Plaid's Auth flow
  // POST /plaid/link-tokens/retrieve
  const retrieveLinkToken = async (): Promise<{
    linkToken: string;
    options?: LinkTokenOptions;
  }> => {
    const token = tokenHelper.getJwtToken();
    const { data } = await api.post<{
      linkToken: string;
    }>(
      plaidPaths.retrieveLinkTokens,
      {},
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(token),
        },
      }
    );
    return data;
  };

  // create a link token for Plaid's IDV flow
  // POST /plaid/link-tokens/identity-verification
  const createLinkTokenforIDV = async ({
    requestId,
    onboardingApplicationId,
    templateUuid,
  }: {
    requestId: string;
    onboardingApplicationId: string;
    templateUuid: string;
  }): Promise<{
    linkToken?: string;
  }> => {
    if (!onboardingApplicationId) {
      return {};
    }

    const token = tokenHelper.getJwtToken();
    const { data } = await api.post<{
      linkToken: string;
    }>(
      plaidPaths.createLinkTokenForIDV,
      {
        requestId,
        onboardingApplicationId,
        templateUuid,
      },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(token),
        },
      }
    );
    return data;
  };

  // create a link token for Plaid's income verification flow
  // POST /plaid/income-verification/link/token/create
  const createIncomeLinkToken = ({
    overrideUserId,
  }: {
    overrideUserId?: string;
  }) => {
    const token = tokenHelper.getJwtToken();
    return api.post<{
      linkToken: string;
    }>(
      plaidPaths.createIncomeLinkToken,
      {
        verificationSourceType: "Payroll",
        ...(overrideUserId && {
          user: {
            id: overrideUserId,
            type: "demo",
          },
        }),
      },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(token),
        },
      }
    );
  };

  // exchange public token for access token and create Plaid Item and Plaid Account
  // POST /plaid/auth
  const linkAuthAccount = async ({
    publicToken,
  }: {
    publicToken: string;
  }): Promise<Array<PlaidAccount>> => {
    const token = tokenHelper.getJwtToken();
    const {
      data: { records },
    } = await api.post<{ records: Array<PlaidAccount> }>(
      plaidPaths.auth,
      { publicToken },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(token),
        },
      }
    );
    return records;
  };

  return {
    createLinkToken,
    createLinkTokenforIDV,
    createIncomeLinkToken,
    retrieveLinkToken,
    linkAuthAccount,
  };
}
