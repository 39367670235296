import useSWR from "swr";
import { useRouter } from "next/router";
import { Flow } from "@dreambigger/shared/src/types";
import useFetcher from "./use-fetcher";
import { api } from "@dreambigger/shared/src/api/acquire";

import { flowPaths } from "./flows";

export default function useFlow(prefetchedData?: Flow) {
  const router = useRouter();
  const { fetcher } = useFetcher(api);

  const flowId = router.query.flowId as string;

  const { data, isValidating, error } = useSWR<Flow>(
    flowId ? flowPaths.getById(flowId) : null,
    fetcher,
    {
      onError: (e) => {
        console.error(e);
      },
      fallbackData: prefetchedData,
      // turn off revalidation
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return { data, isValidating, error };
}
