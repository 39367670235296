import { api } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";

// All the paths for this api domain.
const basePath = "/stripe";
const paths = {
  createIdentityVerificationSession: () =>
    basePath + `/identity/verification-sessions`,
};

export default function useStripe(financialInstitutionId: string) {
  const tokenHelper = useAuthToken(financialInstitutionId);

  // create a verification session
  // POST /identity/verification-sessions
  const createIdentityVerificationSession = (
    configurationUuid: string,
    entityUuid?: string,
    entityType?: string
  ) => {
    return api.post<{
      id: string;
      clientSecret: string;
    }>(
      paths.createIdentityVerificationSession(),
      {
        configurationUuid,
        entity: {
          uuid: entityUuid,
          type: entityType,
        },
      },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(
            tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };

  return {
    createIdentityVerificationSession,
  };
}
