import { AxiosInstance } from "axios";
import { Flow } from "@dreambigger/shared/src/types"

// All the paths for this api domain.
export const basePath = "/flows";
export const flowPaths = {
  getById: (id: string) => basePath + `/${id}`,
  getActiveIds: () => basePath + `/active-ids`,
};

class FlowApi {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  // Get flow details by id.
  // Get /flows/<id>
  getActiveIds = () => {
      return this.api.get(flowPaths.getActiveIds());
  };

  // Get flow details by id.
  // Get /flows/<id>
  getById = (id: string) => {
    return this.api.get<Flow>(flowPaths.getById(id));
  };
}

export default FlowApi;
