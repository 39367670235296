import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useAuthToken from "../../hooks/use-auth-token";
import { Spin, message } from "@dreambigger/design-system";
import { useSegment } from "../../hooks";

type Props = {
  authUrl: any;
  financialInstitutionId?: string;
  refreshToken: string | undefined | null;
  verifyRefreshToken: (token: string) => Promise<boolean>;
  children: JSX.Element;
};

function PasswordlessAuthProvider({
  authUrl,
  financialInstitutionId,
  refreshToken,
  verifyRefreshToken,
  children,
}: Props) {
  const router = useRouter();
  const segment = useSegment();

  // We start off with a state of verifying = null
  // once verification starts we will set to true
  // once verification completes we will set to false
  const [verifyingRefreshToken, setVerifyingRefreshToken] =
    useState<Boolean | null>(null);

  const tokenHelper = useAuthToken(financialInstitutionId);

  // On load, check if we need to refresh our JWT token
  // and if a refresh token exists in the query params.
  // null = query param still loading
  // undefined = no refresh token in the url

  useEffect(() => {
    // handle 8 cases based on refresh token, verification status and jwt token
    // case 1: refresh token query param is being extracted
    // case 2: refresh token exists and verification has not started
    // case 3: verification is in progress and valid jwt token doesn't exist
    // case 4: verification is in progress and valid jwt exists
    // case 5: verification is complete and valid jwt doesn't exist
    // case 6: verification is complete and valid jwt exists
    // case 7: refresh token doesn't exist and valid jwt token doesn't exist
    // case 8: refresh token doesn't exist and valid jwt exists

    // case 1: refreshTokenQueryParam is being extracted
    // wait for it to finish.
    if (refreshToken === null) {
      return;
    }

    // case 2: refresh token exists and verification has not started
    // verify the refresh token
    if (refreshToken && verifyingRefreshToken === null) {
      //clear any existing jwt token
      tokenHelper.removeJwtToken();

      // start verification
      setVerifyingRefreshToken(true);

      verifyRefreshToken(refreshToken)
        .then((isComplete) => setVerifyingRefreshToken(!isComplete))
        .catch((error) => {
          segment.track({
            action: "Authentication",
            label: "Refresh Token Verification Failed",
            properties: {
              error: error.message,
            },
          });
          message.error(
            "An unexpected error occurred. Please contact support."
          );
          router.push(authUrl);
        });
      return;
    }

    // case 3: verification is in progress and valid jwt token doesn't exist
    // wait for it to finish
    if (verifyingRefreshToken && tokenHelper.jwtIsExpired()) {
      return;
    }

    // set verification is complete for all remaining cases
    if (verifyingRefreshToken) {
      setVerifyingRefreshToken(false);
    }

    // valid jwt exists
    // case 4: verification is in progress and valid jwt exists
    // case 6: verification is complete and valid jwt exists
    // case 8: refresh token doesn't exist and valid jwt exists
    if (!tokenHelper.jwtIsExpired()) {
      return;
    }

    // deny access
    // case 5: verification is complete and valid jwt doesn't exist
    // case 7: refresh token doesn't exist and valid jwt token doesn't exist
    // show error and redirect to auth url
    // auth url should not be wrapped by auth provider
    message.error("Access denied. Please verify your identity to continue.");
    router.push(authUrl);
  }, [refreshToken, verifyingRefreshToken, tokenHelper.jwtIsExpired()]);

  return tokenHelper.jwtIsExpired() ? (
    <Spin style={{ width: "100%", marginTop: 150 }} />
  ) : (
    children
  );
}

export default PasswordlessAuthProvider;
