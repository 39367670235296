import useSWR from "swr";
import { message } from "antd";
import {
  ApplicationStatus,
  ResponseStep,
  OnboardingApplication,
} from "@dreambigger/shared/src/types";
import { api } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";
import { useFetcher } from "./";
import axios from "axios";
import { useRouter } from "next/router";
// All the paths for this api domain.
const basePath = "/onboarding-applications";
const paths = {
  applicationById: (id: string) => basePath + `/${id}`,
  myApplications: () => basePath + `/me`,
  signingUrlById: (id: string) => basePath + `/${id}/docusign-signing-url`,
  getAtomicSessionUrl: (id: string) => basePath + `/${id}/atomic-session-url`,
  getNextStep: (id: string) => basePath + `/${id}/next-step`,
  pullCorelationCredit: (id: string) =>
    basePath + `/${id}/corelation-credit-pull`,
  pollFinancialApplicationCreditReport: (id: string) =>
    basePath + `/${id}/poll-financial-application-credit-report`,
};

const useApplication = (financialInstitutionId: string) => {
  const router = useRouter();
  const tokenHelper = useAuthToken(financialInstitutionId);
  const { fetchWithToken } = useFetcher(api, financialInstitutionId);

  const { data: applications, mutate } = useSWR<
    { records: OnboardingApplication[] },
    any
  >(
    tokenHelper.jwtIsExpired()
      ? null
      : [paths.myApplications(), tokenHelper.getJwtToken()],
    fetchWithToken,
    {
      onError: (error) => {
        console.error(error);
        message.error("Something went wrong loading this application.");
      },
    }
  );

  const find = (flowId: string, applicationStatus: ApplicationStatus) => {
    if (!applications) {
      return;
    }
    return applications.records.find(
      (application) =>
        application.flowId === flowId &&
        application.status === applicationStatus
    );
  };

  const findResponse = (
    flowId: string,
    applicationStatus: ApplicationStatus,
    stepSlug: string
  ) => {
    if (!applications) {
      return;
    }
    const application = find(flowId, applicationStatus);
    if (!application) {
      return;
    }
    return application.responses.find((response) => response.slug === stepSlug);
  };

  // Find response for a specific application by providing the flow step's slug and id.
  const findResponseByApplicationId = (
    applicationId: string,
    stepSlug: string
  ) => {
    if (!applications) {
      return;
    }

    const application = applications.records.find(
      (application) => application.id === applicationId
    );

    if (!application) {
      return;
    }

    return application.responses.find((response) => response.slug === stepSlug);
  };

  const findResponseField = (
    flowId: string,
    applicationStatus: ApplicationStatus,
    stepSlug: string | undefined,
    fieldKey: string | undefined
  ) => {
    if (!stepSlug || !fieldKey) {
      return;
    }
    const response = findResponse(flowId, applicationStatus, stepSlug);
    if (!response) {
      return;
    }
    return response.fields[fieldKey];
  };

  const create = async (
    flowId: string,
    response: ResponseStep,
    utmParams?: Record<string, any>,
    options?: { prefillRequestUuid?: string }
  ) => {
    try {
      await api.post(
        basePath,
        {
          flow: {
            id: flowId,
          },
          response,
          utmParams,
          options,
        },
        {
          headers: {
            Authorization: tokenHelper.formatAuthHeader(
              tokenHelper.getJwtToken()
            ),
          },
        }
      );
      await mutate();
    } catch (e) {
      // handle API error if user must be redirected to status portal
      if (
        axios.isAxiosError(e) &&
        e.response?.status === 303 &&
        e.response?.data?.redirectUrl
      ) {
        window.location.href = e.response.data.redirectUrl;
        return;
      }
      //handle API error when only one submitted application is allowed
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        message.error(
          e.response?.data?.error ||
            "Only one submitted application is allowed. Please contact support"
        );
        router.replace(`/errors/${financialInstitutionId}/invalid`);
        return;
      }
      console.log(e);
      message.error("Unable to create application");
    }
  };

  const update = async (
    applicationId: string,
    response: ResponseStep,
    applicationSubmitted = false
  ) => {
    try {
      await api.patch(
        paths.applicationById(applicationId),
        {
          response,
          applicationSubmitted,
        },
        {
          headers: {
            Authorization: tokenHelper.formatAuthHeader(
              tokenHelper.getJwtToken()
            ),
          },
        }
      );
      await mutate();
    } catch (e) {
      console.log(e);
      message.error("Unable to save application");
    }
  };

  const close = async (application: OnboardingApplication) => {
    try {
      // TODO: create a separate endpoint for cancelled applications
      await api.patch(
        paths.applicationById(application.id),
        {
          response: application.responses[0],
          applicationCanceled: true,
        },
        {
          headers: {
            Authorization: tokenHelper.formatAuthHeader(
              tokenHelper.getJwtToken()
            ),
          },
        }
      );
      await mutate();
    } catch (e) {
      console.log(e);
      message.error("Unable to save application");
    }
  };

  // Generate a signing url for an embedded docusign document.
  const generateDocuSignSigningUrl = async ({
    applicationId,
    envelopeId,
    returnUrl,
    docuSignTemplateUuid,
  }: {
    applicationId: string;
    envelopeId?: string;
    returnUrl: string;
    docuSignTemplateUuid: string;
  }) => {
    return api.post(
      paths.signingUrlById(applicationId),
      { envelopeId, returnUrl, docuSignTemplateUuid },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(
            tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };

  // Get an atomic web view url to instantiate an iframe with.
  const getAtomicSessionUrl = async ({
    applicationId,
    accounts,
    atomicConfigurationUuid,
  }: {
    applicationId: string;
    accounts: {
      accountNumber: string;
      routingNumber: string;
      accountType: string;
      title: string;
    }[];
    atomicConfigurationUuid: string;
  }) => {
    return api.post(
      paths.getAtomicSessionUrl(applicationId),
      { accounts, atomicConfigurationUuid },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(
            tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };

  // Run rules to determine the next step in the flow.
  const getNextStep = async ({
    applicationId,
    stepSlug,
    transformUUID,
  }: {
    applicationId: string;
    stepSlug: string;
    transformUUID?: string;
  }) => {
    return api.post<{
      nextStep?: string;
    }>(
      paths.getNextStep(applicationId),
      { stepSlug, transformUUID },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(
            tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };

  // Run Corelation Credit Pull for the application.
  const pullCorelationCredit = async (
    applicationId: string,
    params?: { creditPullIntervalDays?: number }
  ) => {
    return api.post(paths.pullCorelationCredit(applicationId), params, {
      headers: {
        Authorization: tokenHelper.formatAuthHeader(tokenHelper.getJwtToken()),
      },
    });
  };

  const pollFinancialApplicationCreditReport = async (
    applicationId: string
  ) => {
    return api.get(paths.pollFinancialApplicationCreditReport(applicationId), {
      headers: {
        Authorization: tokenHelper.formatAuthHeader(tokenHelper.getJwtToken()),
      },
    });
  };

  return {
    data: applications?.records,
    find,
    findResponse,
    create,
    update,
    close,
    findResponseByApplicationId,
    findResponseField,
    generateDocuSignSigningUrl,
    getAtomicSessionUrl,
    getNextStep,
    pollFinancialApplicationCreditReport,
    pullCorelationCredit,
  };
};

export default useApplication;
