import { AxiosInstance } from "axios";
import { Page } from "@dreambigger/shared/src/types";

// All the paths for this api domain.
export const basePath = "/pages";
export const pagePaths = {
  getById: (id: string) => basePath + `/${id}`,
  getActiveIds: () => basePath + `/active-ids`,
};

class PageApi {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  getActiveIds = () => {
    return this.api.get(pagePaths.getActiveIds());
  };

  // Get page details by id.
  // Get /pages/<id>
  getById = (id: string) => {
    return this.api.get<Page>(pagePaths.getById(id));
  };
}

export default PageApi;
