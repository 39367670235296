import { api } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";
import { RefObject } from "react";
import html2canvas from "html2canvas";

// All the paths for this api domain.
const basePath = "/onboarding-applications";
const paths = {
  capture: (id: string) => basePath + `/${id}/screenshot`,
};

export default function useScreenshot(financialInstitutionId: string) {
  const tokenHelper = useAuthToken(financialInstitutionId);

  // Save a screenshot of the provided ref
  // POST /onboarding-applications/:id/screenshot
  const capture = async (
    applicationId: string,
    ref: RefObject<HTMLDivElement>,
    slug: string
  ) => {
    if (!ref.current) {
      return;
    }
    const timestamp = new Date().toISOString();
    const screenshotName = `${slug}_${applicationId}_${timestamp}`;

    // Take screenshot of element and convert to blob
    const screenshot = await html2canvas(ref.current, { useCORS: true });
    screenshot.toBlob(async (blob) => {
      if (!blob) {
        console.error("Failed to capture screenshot as blob.");
        return;
      }

      // Upload the blob as formData
      const formData = new FormData();
      formData.append("file", blob, `${screenshotName}.png`);
      formData.append("slug", slug);
      try {
        await api.post(paths.capture(applicationId), formData, {
          headers: {
            Authorization: tokenHelper.formatAuthHeader(
              tokenHelper.getJwtToken()
            ),
          },
        });
      } catch (error) {
        console.error("Failed to upload screenshot.", error);
      }
    }, "image/png");
  };

  return {
    capture,
  };
}
